import API from "@/api";

export default {
  getList: params => {
    return API.requestGet({
      url: "admin/getList",
      params
    });
  },
  // 获取角色选项
  roles: params => {
    return API.requestGet({
      params,
      url: "role/getRoles"
    });
  },
  // 新增账号
  add: params => {
    return API.requestPost({
      data: params,
      url: "admin/add"
    });
  },
  edit: params => {
    return API.requestPost({
      data: params,
      url: "admin/edi"
    });
  },
  del: params => {
    return API.requestPost({
      data: params,
      url: "admin/del"
    });
  }
};
