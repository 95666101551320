<template>
  <div class="add">
    <el-dialog
      :title="showStatus === 2 ? '编辑账号' : '添加账号'"
      :visible="!!showStatus"
      width="40%"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="90px"
        class="form"
        hide-required-asterisk
      >
        <template v-if="showStatus === 1">
          <el-form-item label="昵称" prop="nickname">
            <el-input placeholder="请输入昵称" v-model.trim="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="account">
            <el-input v-model.trim="form.account" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model.number="form.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
        </template>
        <el-form-item :label="showStatus === 2 ? '修改密码' : '输入密码'" prop="password">
          <el-input v-model.trim="form.password" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repsd">
          <el-input v-model.trim="form.repsd" placeholder="请确认密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="微信号码" prop="wechat">
          <el-input v-model.trim="form.wechat" placeholder="请输入微信号码"></el-input>
        </el-form-item>
        <el-form-item label="微信二维码">
          <div class="pic_list_box">
            <span @click="delImage" class="delBtn"><i class="el-icon-error" v-if="imageUrl"></i></span>
            <div class="pic_list" v-if="imageUrl">
              <img :src="imageUrl" alt srcset />
            </div>
            <i class="el-icon-upload" v-else></i>
            <input
              type="file"
              :disabled="imageUrl? true : false"
              accept="jpg, png, gif"
              @change="changeImage($event)"
              ref="avatarInput"
              style="opacity: 0;
                    z-index: 10;
                    position: absolute;
                    height: 160px;
                   width: 200px;"
            />
          </div>
        </el-form-item>
        <el-form-item label="角色选择" prop="roleId">
          <el-select v-model="form.roleId" placeholder="选择角色">
            <el-option
              v-for="item in roleOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="当前状态" prop="status">
          <el-select v-model="form.status" placeholder="选择状态">
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="ft-sz-12 ft-bold">* 账号禁用后，该账号下的推广链接均会被禁用。</div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 参数说明：
 * @showStatus  => 0: 关闭, 1: 添加, 2: 编辑
 * @editData => 被编辑行row
 */
import { base, baseUrl } from '@/api/env';
import service from '../api';
export default {
  props: {
    showStatus: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    var checkPsd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.repsd !== '') {
          this.$refs.form.validateField('repsd');
        }
        callback();
      }
    };
    var checkRepsd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        nickname: '',
        account: '',
        mobile: '',
        password: '',
        repsd: '',
        roleId: '',
        status: null,
        wechat: ''
      },
      roleOpts: [],
      statusOpts: [
        { value: 0, label: '正常' },
        { value: 1, label: '禁用' }
      ],
      rules: {
        nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          {
            pattern: /^[a-zA-z]\w{3,9}$/,
            message: '账号应为4-10位字符，可使用数字、字母、下划线，且以字母开头',
            trigger: 'blur'
          }
        ],
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确格式的手机号', trigger: 'blur' }],
        password: [
          { validator: checkPsd, trigger: 'blur' },
          {
            pattern: /^[a-zA-z0-9]\w{5,19}$/,
            message: '6-20位字符，由数字、字母、下划线组成，且不能以下划线开头',
            trigger: 'blur'
          }
        ],
        repsd: [{ validator: checkRepsd, trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        wechat: [
            // {required: true, message: '请输入微信号码', trigger: 'blur'},
            {pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/, message: '请输入正确格式的微信号', trigger: 'blur'}
        ],
      },
        imageUrl: "",
        imageID: "",
        showImg: false,
        width: '45%'
    };
  },
  methods: {
    cancel() {
      this.showImg = false;
      this.imageUrl = '';
      this.$refs['form'].resetFields();
      this.$emit('cancel');
    },
    // 获取角色选项
    getRoles() {
      return service.roles().then(res => {
        let roleOpts = [];
        res.forEach(item => {
          roleOpts.push({
            value: item.id,
            label: item.roleName
          });
        });
        this.roleOpts = roleOpts;
      });
    },
    submit() {
      let check = true;
      let { showStatus, form } = this;
      if (showStatus === 2 && (form.password || form.repsd)) {
        // 编辑状态且填写了密码，才校验密码
        this.$refs['form'].validateField(['password', 'repsd'], err => (check = !err));
      } else if (showStatus === 1) {
        // 添加账号校验整个表单
        this.$refs['form'].validate(res => (check = res));
      }
      if (!check) return;
      this.form.wechatQrcode = this.imageID;
      this.$emit('submit-form', this.form);
      this.imageUrl = '';
      this.imageID = "";
      this.$refs['form'].resetFields();
    },
    openDialog() {
      // 填充编辑表单数据
      if (this.showStatus === 2) {
        this.$nextTick(() => {
          let { form, editData, roleOpts } = this;
          let { roleName = '', status = null , wechat } = editData;
          form.status = status;
          // 匹配角色
          let matchRole = roleOpts.find(item => item.label === roleName);
          form.roleId = matchRole ? matchRole.value : '';

          form.wechat = wechat;
          this.showImg = editData.wechat_qrcode?true:false
          this.imageUrl =  this.showImg? base+'api/common/attach/'+editData.wechat_qrcode:''
        });
      }
    },
    changeImage(e) {
        // 上传图片事件
        var files = this.$refs.avatarInput.files;
        let param = new FormData();
        param.append('file', files[0]);
        this.$axios
            .post(base + 'api/common/attach', param, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(res => {
                this.imageID = res.data.id;
                this.imageUrl = res.data.url;
            })
            .catch(err => {
                // return reject(err);
            });
    },
    //删除二维码
    delImage(){
      this.showImg = false;
      this.imageUrl = '';
      this.imageID = 0;
    }
  },
  mounted() {
    this.getRoles();
  }
};
</script>

<style lang="scss" scoped>
  .add {
    .form {
      width: 85%;
      text-align: left;
      margin: 0 auto;
    }
  }
  .pic_list_box {
    display: flex;
    border: 1px dashed #d9d5d5;
    // background: url('../../');
    position: relative;height: 160px;
    width: 200px;
    .delBtn{
      z-index: 100;
      display: inline-block;
      width:18px;
      height:18px;
      line-height: 18px;
      text-align: center;
    }
    .upload_btn {
      width: 80px;
      height: 80px;
      padding-left: 15px;
    }
    .el-icon-upload{
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left:-16px;
      margin-top: -16px;
    }
    .pic_list {
      margin: 4px auto;
      img {
        width: 160px;
        height: 160px;

        padding-bottom: 10px;margin: 0 auto;
      }
    }
  }
</style>
