<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @show-add="showStatus = 1" @search="searchList"></search-form>
    <!-- 角色列表 -->
    <account-table :list="list" @show-edit="showEdit" @delete="handleDel"></account-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
    <!-- 添加账号 -->
    <add-form
      :show-status="showStatus"
      :edit-data="editData"
      @cancel="showStatus = 0"
      @submit-form="submit"
    ></add-form>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import accountTable from './components/account-table';
import addForm from './components/add-form';

import pagination from '@/mixins/pagination';
import service from './api';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      showStatus: 0,
      editData: {},
      searchData: {}
    };
  },
  components: {
    searchForm,
    accountTable,
    addForm
  },
  methods: {
    init() {
      let initParams = { pageNum: 1, pageSize: 10 };
      return this.getList(initParams);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 显示编辑框
    showEdit(row) {
      this.showStatus = 2;
      this.editData = row;
    },
    // 搜索
    searchList(data) {
      let params = Object.assign({ pageNum: 1 }, data);
      this.searchData = data;
      return this.getList(params);
    },
    // 提交表单
    submit(data) {
      if (this.showStatus === 2) {
        // 编辑账号
        let edit = { password: '', roleId: null, status: null ,wechat: '', wechatQrcode: '' };
        for (let key in edit) {
          edit[key] = data[key];
        }
        let params = Object.assign({ id: this.editData.id }, edit);
        return this.handleSubmit(service.edit(params));
      } else {
        // 添加账号
        return this.handleSubmit(service.add(data));
      }
    },
    // 统一处理提交
    handleSubmit(r) {
      return r.then(res => {
        this.showStatus = 0;
        this.$message({ type: 'success', message: '操作成功！', offset: 400 });
        this.init();
      });
    },
    // 删除账号
    handleDel(row) {
      this.$alert(`确定将账号${row.account || ''}永久删除？`, '提示', { showCancelButton: true }).then(() => {
        return service.del({ id: row.id }).then(res => {
          this.$message({ type: 'success', message: '删除成功' });
          this.init();
        });
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
</style>
